import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Send from '../../assets/send'
import "./contact.css"

const Contact = () => {
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_c5323od', 'template_k0cw6xf', form.current, 'CTFHbygerzg5sZsIF')
    e.target.reset();
  };
  return (
   <section className="contact section" id="contact">
    <h2 className="section__title">Get in touch</h2>
    <span className="section__subtitle">Contact Me</span>

    <div className="contact__container container grid">
        <div className="contact__content">
            <h3 className="contact__title">Talk to me</h3>

            <div className="contact__info">
                <div className="contact__card">
                    <i className="bx bx-mail-send contact__card-icon"></i>

                    <h3 className="contact__card-title">Email</h3>
                    <span className="contact__card-data">
                        harshsinghsde@gmail.com
                    </span>

                    <a href="" className="contact__button">Write me {" "}<i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
                </div>

                <div className="contact__card">
                    <i className="bx bxl-whatsapp contact__card-icon"></i>

                    <h3 className="contact__card-title">Whatsapp</h3>
                    <span className="contact__card-data">
                        885-592-4997
                    </span>

                    <a href="" className="contact__button">Write me <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
                </div>

                <div className="contact__card">
                    <i className="bx bxl-messenger contact__card-icon"></i>

                    <h3 className="contact__card-title">Messenger</h3>
                    <span className="contact__card-data">
                        harshsinghsde@gmail.com
                    </span>

                    <a href="mailto:exampleemail@gmail.com" className="contact__button">Write me <i className="bx bx-right-arrow-alt contact__button-icon"></i></a>
                </div>
            </div>
        </div>

        <div className="contact__content">
            <h3 className="contact__title">Write me your project</h3>

            <form ref={form} onSubmit={sendEmail} className="contact__form">
                <div className="contact__form-div">
                    <label className="contact__form-tag">Name</label>
                    <input type="text" name="name" className="contact__form-input" placeholder="Insert your name" />
                </div>

                <div className="contact__form-div">
                    <label className="contact__form-tag">Mail</label>
                    <input type="email" name="email" className="contact__form-input" placeholder="Insert your mail" />
                </div>
                <div className="contact__form-div">
                    <label className="contact__form-tag">Project</label>
                    <textarea cols="30" rows="10" name="project" className="contact__form-input contact__form-area" placeholder="Write your Project" />
                </div>
                <button href="#contact" className="button contact-button button--flex">
            Send Message <Send />
        </button>
            </form>
        </div>
    </div>
   </section>
  )
}

export default Contact