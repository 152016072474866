import CarInfoWebsite from "../../assets/CarInfoWebsite.png";
import AnimeCatalogue from "../../assets/AnimeCatalogue.png";
import ThreadsApp from "../../assets/ThreadsApp.png";
import Work3 from "../../assets/work3.jpg"
import Work4 from "../../assets/work4.jpg"
import InteriorDesignWebsite from "../../assets/InteriorDesignWebsite.png"

export const projectsData = [
    {
        id: 1,
        image: CarInfoWebsite,
        title: 'CarHub',
        category: 'frontend',
        link:'https://car-showcase-roan-mu.vercel.app/'
    },
    {
        id: 2,
        image: AnimeCatalogue,
        title: 'Anime Catalogue',
        category: 'frontend',
        link:'https://anime-catalogue.vercel.app/'
    },
    {
        id: 3,
        image: Work3,
        title: 'Brand design',
        category: 'design',
        link:'#'
    },
    {
        id: 4,
        image: ThreadsApp,
        title: 'Threads App',
        category: 'fullstack',
        link:'https://threads-app-sandy-zeta.vercel.app/'
    },
    {
        id: 5,
        image: InteriorDesignWebsite,
        title: 'Interior Plants Website',
        category: 'frontend',
        link:'https://flora-foundation.vercel.app/'
    },
];

export const projectsNav = [
    {
        name:'all',
    },
    {
        name: 'frontend'
    },
    {
        name:'fullstack'
    },
    {
        name:'design'
    },
]